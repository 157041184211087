@import url('https://fonts.googleapis.com/css2?family=Mukta&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mukta&family=Ubuntu:wght@500&display=swap');

html{
    scroll-behavior:smooth;
}

*,
*::before,
*::after{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.wrapper{
    height: 100vh;
    width: 100vw;
    background-image: url("../components/pxfuel.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.header{
    height: 120px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header-left{
    width:25%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 10px;
    transform: translateY(-2.5%);
}

.header-left img{
    margin-top: 1rem;
    margin-left: 2rem;
    border-radius: 50%;
}

.header-left h1{
    font-size: 40px;
    color: orange;
    margin-left: 0.8rem;
    margin-top: 1rem;
    margin-right: 2.8rem;
}

.header-right{
    width: 30%;
    height: 50px;
    margin-top: 1.9rem;
    margin-right: 5.8rem;
}

.header-right ul{
    display: flex;
    justify-content: space-between;
}

li{
    list-style: none;
}

li a{
    text-decoration: none;
    color:white;
    font-size: 1.7rem;
}

li a:hover{
    opacity: 0.8;
}

.main{
    height: 650px;
    color:white;
    margin-top: 0.5rem;
    margin-left: 7rem;
    /* border: 1px solid white; */
    margin-right: 5rem;
}

.main h1{
    width:700px;
    font-size: 3rem;
}

.content{
    display: flex;
}

.main p:first-of-type{
    font-size: 1.3rem;
    width: 510px;
    margin-top: 1rem;
    margin-left: 1rem;
    padding: 0.5rem;
}

.main p:last-of-type{
    padding: 0.5rem;
    width: 450px;
    font-size: 1.3rem;
    margin-left: 1rem;
    margin-top: 1rem;
}

.btn{
    height: 50px;
    width: 120px;
    font-size: 1.2rem;
    padding: 0.5rem;
    margin-top: 1.7rem;
    margin-left:10rem;
    color: brown;
    font-weight: 600;
    cursor:pointer;
    background-color: khaki;
    border:none;
    border-radius: 5px;
    font-family: 'Ubuntu', sans-serif;
    letter-spacing: 1px;
    transition: all 0.1s ease-in;
}

.btn:hover{
    opacity: 0.8;
}

.laptop{
    position: relative;
    top:50%;
    transform: translateX(10%);
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");

*,*::before,*::after{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body{
    font-family: "Poppins", sans-serif;
    --bg-color: #fff;
    --main-hue: 208;
    --main-color: hsl(var(--main-hue), 92%, 54%);
    --main-color-hover: hsl(var(--main-hue), 77%, 48%);
    --light-main-color: hsl(var(var(--main-hue), 91%, 55%));
    --text-color: #000;
    --light-text-color: #9ca7b6;
    --input-bg:  hsla(var(--main-hue), 50%, 50%, 6.5%);
    --input-bg-hover: hsla(var(--main-hue), 50%, 50%, 14%);
    --input-text: #8c9aaf;
    --nav-color: hsl(var(--main-hue), 17%, 79%);
}

.contact{
    width: 100%;
    overflow: hidden;
    background-color: var(--bg-color);
}

.contact .container{
    height: 100vh;
    min-height: 700px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    margin: 0 auto;
    /* transform: translateY(-5%); */
}

.contact .container > div{
    grid-column: 1/2;
    grid-row: 1/2;
}

.left{
    width: 100%;
    max-width: 80rem;
    margin: 0 auto;
    padding: 65px 1.5rem 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    position: relative;
    z-index: 3;
}

.contact-heading h1{
    font-weight: 600;
    color: var(--text-color);
    font-size: 3.5rem;
    line-height: 0.9;
    white-space: nowrap;
    margin-bottom: 1.2rem;
}

.contact-heading h1 span{
    color: var(--main-color);
}

.text{
    color: var(--light-text-color);
    line-height: 1.1;
    font-size: 1rem;
}

.text a{
    color: var(--main-color);
    transition: 0.3s;
}

.text a:hover{
    color: var(--main-color-hover);
}

.form-wrapper{
    max-width: 32rem;
}

.contact-form{
    display: grid;
    margin-top: 2.55rem;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 2rem;
    row-gap: 1rem;
}

.input-wrap{
    position: relative;
}

.input-wrap.w-100{
    grid-column: span 2;
}

.contact-input{
    width: 100%;
    background-color: var(--input-bg);
    padding: 1.5rem 1.35rem calc(0.75rem - 2px) 1.35rem;
    border: none;
    outline: none;
    font-family: inherit;
    border-radius: 20px;
    color: var(--text-color);
    font-weight: 600;
    font-size: 0.95rem;
    border: 2px solid transparent;
    box-shadow: 0 0 0 0px hsla(var(--main-hue), 92%, 54%, 0.169);
    transition: 0.3s;
}

.contact-input:hover{
    background-color: var(--input-bg-hover);
}

.input-wrap label{
    position: absolute;
    top:50%;
    left: calc(1.35rem + 2px);
    transform: translateY(-50%);
    color: var(--light-text-color);
    pointer-events: none;
    transition: 0.25s;
}

.input-wrap .icon{
    position: absolute;
    right: calc(1.35rem + 2px);
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    color: var(--input-text);
    font-size: 1.25rem;
    transition: 0.3s;
}

textarea.contact-input{
    resize: none;
    width: 100%;
    min-height: 150px;
}

textarea.contact-input ~ label{
    top: 1.2rem;
    transform: none;
}

textarea.contact-input ~ .icon{
    top: 1.3rem;
    transform: none;
}

.input-wrap.focus .contact-input{
    background-color: var(--bg-color);
    border: 2px solid var(--light-main-color);
    box-shadow: 0 0 0 5px hsla(var(--main-hue), 91%, 55%, 0.11);
}

.input-wrap.focus label{
    color: var(--light-main-color);
}

.input-wrap.focus .icon{
    color: var(--light-main-color);
}

.input-wrap.not-empty label{
    font-size: 0.66rem;
    top: 0.75rem;
    transform: translateY(0);
}

.contact-buttons{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 1rem;
    margin-top: 1rem;
    width: 100%;
    grid-column: span 2;
}

.button{
    display: inline-block;
    padding: 1.1rem 2rem;
    background-color: var(--main-color);
    color: #ffffff;
    border-radius: 40px;
    border: none;
    font-family: inherit;
    font-weight: 500;
    font-size: 1rem;
    cursor: pointer;
    transition: 0.3s;
}

.button:hover{
    background-color: var(--main-color-hover);
}

.button.upload{
    position: relative;
    background-color: var(--input-bg);
}

.button.upload span {
    color: var(--light-text-color);
}

.button.upload:hover {
    color: var(--input-bg-hover);
    background-color: var(--main-color); 
    transition: background-color 0.3s;
}

.button.upload input{
    position: absolute;
    width: 100%;
    height: 100%;
    top:0;
    left: 0;
    cursor: pointer;
    opacity: 0;
}


.right{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    overflow: hidden;
    pointer-events: none;
}

.image-wrapper{
    position: relative;
    grid-column: 2;
}

.image-wrapper .img{
    position: absolute;
    height: 130%;
    left: 60%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.wave-wrap{
    position: absolute;
    width: 100%;
    right: 100%;
    top: 0;
    height: 100%;
    background-color: var(--bg-color);
}

.wave-wrap svg{
    position: absolute;
    height: calc(100% + 10px);
    top: 50%;
    transform: translateY(-50%);
    left: calc(100% - 2px);
}

#wave{
    fill: var(--bg-color);
    height: 100%;
}


.dashed-wave{
    position: absolute;
    z-index: 3;
    height: 130%;
    bottom: 60%;
    left: -28px;
    transform: translateY(50%);
}

#dashed-wave{
    fill:none;
    stroke: var(--nav-color);
    stroke-width: 1px;
    stroke-dasharray: 6.5;
    opacity: 0.8;
}

.footer{
    background-color: #6674CC;
    height: 230px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-family: inherit;
    letter-spacing: 1px;
    font-size: 1.2rem;
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
}

.uppercontent{
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.footerhead{
    padding-top: 1rem 0;
    font-weight: 700;
    transform: translateX(-90%);
    font-size: 2rem;
    margin-left: 2rem;
}

.leftportion{
    height: 155px;
    margin-top: 1rem;
    font-family: inherit;
    display: flex;
    justify-content: space-between;
    width: 300px;
}

.leftportion ul{
    margin-top: 0.8rem;
}

.leftportion ul li a{
    font-size: 1.1rem;
}

.leftportion p{
    text-transform: uppercase;
    font-weight: bold;
}

.lps{
    transform: translateX(50%);
}

.rightportion{
    width: 300px;
    position: relative;
    left: 10%;
    border-left: 1px solid white;
    padding-left: 1rem;
}

.lowercontent{
    transform: translateY(-30%);
    padding-top: 1rem;
    border-top: 1px solid white;
}